import SSRProvider from 'react-bootstrap/SSRProvider';

import Header from './Header';
import Footer from './Footer';

const Layout = ({ children }) => {
  return (
    <SSRProvider>
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <main>{children}</main>
        <div className="mt-auto">
          <Footer />
        </div>
      </div>
    </SSRProvider>
  );
};

export default Layout;
