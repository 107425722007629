import React from 'react';
import Link from 'next/link';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'next/image';

import ConnectButton from './ConnectButton';

const Header = () => {
  return (
    <Navbar expand="lg" style={{ backgroundColor: '#ffa2ce' }}>
      <div className="container">
        <Link href="/" passHref>
          <Navbar.Brand className="d-flex align-items-center">
            <Image src="/pfp.png" width={48} height={48} alt="Noundles Logo" />
            <span className="ps-1 d-none d-md-block">Noundles</span>
          </Navbar.Brand>
        </Link>
        <div className="ms-auto order-lg-1">
          <ConnectButton autoconnect />
        </div>
        <Navbar.Toggle aria-controls="navbar-collapse" className="ms-3" />
        <Navbar.Collapse id="navbar-collapse">
          <Nav>
            <Nav.Item>
              <Link href="/" passHref>
                <Nav.Link>Home</Nav.Link>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link href="/stake" passHref>
                <Nav.Link>Dashboard</Nav.Link>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link href="/stake/noundles" passHref>
                <Nav.Link>Stake</Nav.Link>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link href="/shop" passHref>
                <Nav.Link>Rainbow Shop</Nav.Link>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link href="/story" passHref>
                <Nav.Link>Story</Nav.Link>
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link href="/team" passHref>
                <Nav.Link>Team</Nav.Link>
              </Link>
            </Nav.Item>
          </Nav>
          <Nav className="ms-auto me-3 flex-row">
            <Nav.Item>
              <Nav.Link
                href="https://twitter.com/YourRainbowShop"
                target="_blank"
                className="d-flex pe-2"
              >
                <Image
                  src="/twitter-circle.svg"
                  alt="Twitter"
                  width={32}
                  height={32}
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://instagram.com/noundlesnft"
                target="_blank"
                className="d-flex pe-2"
              >
                <Image
                  src="/instagram-circle.svg"
                  alt="Instagram"
                  width={32}
                  height={32}
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href="https://discord.gg/noundles"
                target="_blank"
                className="d-flex pe-2"
              >
                <Image
                  src="/discord-circle.svg"
                  alt="Discord"
                  width={32}
                  height={32}
                />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
