import React, { useCallback, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useWeb3React } from '@web3-react/core';

import connector from '../wallet/connector';

const ConnectButton = ({ autoconnect, onConnect, onDisconnect }) => {
  const { active, account, activate, deactivate } = useWeb3React();

  const connect = useCallback(async () => {
    try {
      await activate(connector);
      onConnect();
    } catch (e) {
      console.log(e);
    }
  }, [activate, onConnect]);

  const disconnect = useCallback(async () => {
    try {
      await deactivate(connector);
      onDisconnect();
    } catch (e) {
      console.log(e);
    }
  }, [deactivate, onDisconnect]);

  useEffect(() => {
    if (autoconnect) {
      connector.isAuthorized().then((authorized) => {
        if (authorized) {
          connect();
        }
      });
    }
  }, [connect, autoconnect]);

  if (active) {
    const shortAccount = `${account.substring(0, 5)}...${account.substring(
      account.length - 4
    )}`;
    return (
      <Button onClick={disconnect} variant="dark" className="rounded-pill px-3">
        {shortAccount}
      </Button>
    );
  } else {
    return (
      <Button onClick={connect} variant="dark" className="rounded-pill px-3">
        Connect wallet
      </Button>
    );
  }
};

ConnectButton.defaultProps = {
  autoconnect: false,
  onConnect: () => {},
  onDisconnect: () => {},
};

export default ConnectButton;
